// core-js includes polyfills to get the app working on Internet Explorer
import 'core-js';
// whatwg-fetch is a window.fetch polyfill needed for fetch to work in older verisons of Internet Explorer
import 'whatwg-fetch';
import React, { useEffect, useRef, useState } from 'react';
import './index.css';
import App from './App';
import MobileApp from './MobileApp';
import OnboardingLayer from './components/OnboardingLayer';
import MissionsTutorialLayer from './components/MissionsTutorialLayer';
import AppInit from './components/AppInit';
import SocialLoginInterface from 'shared/api/SocialLoginInterface';
import ENVIRONMENT_VARIABLES from 'shared/utils/environmentVariables';
import { Provider } from 'shared/node_modules/react-redux';
import { PersistGate } from 'shared/node_modules/redux-persist/lib/integration/react';
import { persistor, store } from 'shared/state/store';
import { setIsConnected } from 'shared/state/actions/device';
import { setAdBlocker } from 'shared/state/actions/apiRoot';
import { isMobile, isTablet } from 'react-device-detect';
import { ErrorBoundary } from './components/ErrorBoundary';
import ThemeContext from 'shared/context/ThemeContext';
import { themes } from 'shared/context/Themes';
import { NotificationProvider } from './context/NotificationProvider';
import { handleHtmlScripts } from './prependScripts';
import LoadingLayer from './components/Loading/Loading';
import RotateScreen from './screens/RotateScreen/RotateScreen';
import PWAPrompt from 'react-ios-pwa-prompt';
import { isMobileSupported, isNotStandaloneMobilePWA } from './utils/mobileSupported';
import { createGlobalStyle } from 'styled-components';
import useCheckOrientation from './hooks/useCheckOrientation';
import useViewport from './hooks/useViewport';
import BrowserScreen from './screens/BrowserScreen/BrowserScreen';

const GlobalStyle = createGlobalStyle`
  #root {
    --enable-media-queries: 0;
  }
`;

const Redux = () => {
  useViewport();
  const { isPortrait, isLandscape } = useCheckOrientation();

  const [firstRender, setFirstRender] = useState(true);
  const [orientationLoading, setOrientationLoading] = useState(false);
  const [showBrowserMobileScreen, setShowBrowserMobileScreen] = useState(true);
  const willMount = useRef(true);

  useEffect(() => {
    const handleOrientationChange = () => {
      setOrientationLoading(true);
      setTimeout(() => {
        setOrientationLoading(false);
      }, 1000);
    };
    window.matchMedia('(orientation: landscape)').addEventListener('change', handleOrientationChange);
    return () => {
      window.matchMedia('(orientation: landscape)').removeEventListener('change', handleOrientationChange);
    };
  }, []);

  const checkToLoadScripts = () => {
    if (willMount.current) {
      handleHtmlScripts();
      detectAdBlock();
    }
    willMount.current = false;
  };

  checkToLoadScripts();

  useEffect(() => {
    setTimeout(() => {
      !window.isGsiLoaded && SocialLoginInterface.loadGsi();
    }, 1500);
  }, []);

  useEffect(() => {
    if (firstRender && isLandscape) {
      setFirstRender(false);
    }
  }, [firstRender, isLandscape]);

  const mobileSupported = isMobile && isMobileSupported();
  const showPortraitRotateScreen = mobileSupported && isPortrait;
  const showRotateScreenOnFirstRender = showPortraitRotateScreen && firstRender;
  const showMobileAppNotSupported = isMobile && !isMobileSupported();

  return (
    <Provider store={store}>
      <ThemeContext.Provider value={themes[ENVIRONMENT_VARIABLES.APPLICATION_TARGET]}>
        <GlobalStyle />
        <NotificationProvider>
          {orientationLoading && <LoadingLayer loading={orientationLoading} transparency={1} />}
          {showMobileAppNotSupported ? (
            <MobileApp />
          ) : (
            <>
              {showRotateScreenOnFirstRender ? (
                <>
                  <PWAPrompt timesToShow={99} />
                  <RotateScreen />
                </>
              ) : (
                <>
                  {showPortraitRotateScreen && (
                    <div>
                      <PWAPrompt timesToShow={99} />
                      <RotateScreen />
                    </div>
                  )}
                  {(isNotStandaloneMobilePWA || (isTablet && isLandscape)) && showBrowserMobileScreen ? (
                    <BrowserScreen setShowBrowserMobileScreen={setShowBrowserMobileScreen} />
                  ) : (
                    <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
                      <ErrorBoundary>
                        <AppInit>
                          <App />
                          <OnboardingLayer />
                          <MissionsTutorialLayer />
                          <LoadingLayer />
                        </AppInit>
                      </ErrorBoundary>
                    </PersistGate>
                  )}
                </>
              )}
            </>
          )}
        </NotificationProvider>
      </ThemeContext.Provider>
    </Provider>
  );
};

// dispatch events if the browser goes offline/comes back online
window.addEventListener('offline', state => {
  store.dispatch(setIsConnected(false));
});

window.addEventListener('online', state => {
  store.dispatch(setIsConnected(true));
});
// end dispatch events

async function detectAdBlock() {
  let adBlockEnabled = false;
  const googleAdUrl = ENVIRONMENT_VARIABLES.GOOGLE_AD_URL;
  try {
    await fetch(new Request(googleAdUrl)).catch(() => (adBlockEnabled = true));
  } catch (e) {
    adBlockEnabled = true;
  } finally {
    store.dispatch(setAdBlocker(adBlockEnabled));
  }
}

export default Redux;

